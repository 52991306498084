import * as THREE from 'three';
import React, {useRef, useMemo, useLayoutEffect, Suspense, useCallback, useEffect} from 'react';
import {Canvas, useLoader, useFrame, extend} from '@react-three/fiber';
import {PerspectiveCamera, shaderMaterial, Stats} from '@react-three/drei';
import {TextureLoader} from 'three/src/loaders/TextureLoader';
import glsl from 'babel-plugin-glsl/macro';
import {CubicBezier} from "three/src/extras/core/Interpolations";
import {MapControls} from "./controls/MapControls";

import AtlasTerrain from "./atlas/terrain.json";
import {MathUtils} from "three";

const AtlasMaterial = shaderMaterial(
    {
        instanceMatrix: new THREE.Matrix4(),
        map: new THREE.Texture(),
        size: 0,
        u_fogColor: new THREE.Vector4(),
        u_fogNear: 0,
        u_fogFar: 0,
        shouldDiscard: 0,
    },
    // vertex shader
    glsl`
    uniform float size;
    uniform float shouldDiscard;
    
    attribute vec4 offsetAndTileSize;
    attribute float canDiscard;
    
    varying vec2 vUv;
    varying vec3 worldPos;
    varying vec3 camPos;
    varying float cloudAmount;
    varying float fragDiscard;

    void main() {
      vec2 offset = offsetAndTileSize.xy;
      vec2 tileSize = offsetAndTileSize.zw;
      vUv = (offset / size) + (uv * tileSize / size);
      vec4 worldVec = modelMatrix * instanceMatrix * vec4(position, 1.0);
      worldPos = worldVec.xyz;
      
      float worldY = worldPos.y;
      cloudAmount = 0.;
      if(worldY > 15.0) {
        float maxY = 30.0;
        float minY = 15.0;
        cloudAmount = clamp(((worldY - minY) / (maxY - minY)) / 10. * 2.5, 0.0, 1.0);
      }
      
      fragDiscard = 0.;
      if(shouldDiscard > 0.) {
        if(canDiscard <= 0.) {
          worldVec.y = 0.;
        } else {
          fragDiscard = 1.;
        }
      }

      vec4 camVec = viewMatrix * worldVec;
      camPos = camVec.xyz;
      gl_Position = projectionMatrix * camVec;
    }
  `,
    // fragment shader
    glsl`
    uniform sampler2D map;
    uniform vec4 u_fogColor;
    uniform float u_fogNear;
    uniform float u_fogFar;
    
    varying vec2 vUv;
    varying vec3 worldPos;
    varying vec3 camPos;
    varying float fragDiscard;
    varying float cloudAmount;
    
    void main() {
      if(fragDiscard >= 1.) {
        discard;
      }
      vec4 color = texture2D(map, vUv); 
      color = mix(color, vec4(0.8156, 0.8705, 0.9254, 1.0), cloudAmount);
      
      // #ifdef USE_LOGDEPTHBUF_EXT
      //     float depth = gl_FragDepthEXT / gl_FragCoord.w;
      // #else
      //     float depth = gl_FragCoord.z / gl_FragCoord.w;
      // #endif
      float depth = length(camPos);
      float fogAmount = smoothstep(u_fogNear, u_fogFar, depth);
      
      float worldY = worldPos.y;
      if(worldY < 0.0) {
        float maxY = 0.0;
        float minY = -10.0;
        fogAmount = clamp(((maxY - worldY) / (maxY - minY)), fogAmount, 1.0);
      }
      gl_FragColor = mix(color, u_fogColor, fogAmount);  
    }
  `
)

extend({AtlasMaterial})

const tile_num = 100000;
const size = tile_num * 5;
const tempObject = new THREE.Object3D();

function Terrain({focusCameraOn, cameraMovingEventRef, cameraControllerRef}) {
    const colorMap = useLoader(TextureLoader, '/atlas/terrain.jpg');
    // colorMap.minFilter = THREE.LinearMipmapLinearFilter;
    const [offsetAndSizeVectorArray, atlasIndexFromFilename] = useMemo(() => {
        let dataArray = [];
        let indexMap = {};
        let id = 0;
        for (let row of AtlasTerrain.frames) {
            indexMap[row.filename] = id;
            const frame = row.frame;
            dataArray.push([frame.x, AtlasTerrain.meta.size.h - frame.y - frame.h, frame.w, frame.h]);
            id++;
        }
        return [dataArray, indexMap];
    }, []);

    const offsetAndSizeFlatArray = useMemo(() => Float32Array.from(new Array(size * 4).fill().flatMap((_, i) => {

        if (i >= 100000) {
            return offsetAndSizeVectorArray[atlasIndexFromFilename["TabletopBadges_22.PNG"]];
        }

        const z = i % 250;
        const x = Math.floor(i / 250);

        const rand_arr_n1 = [
            ["TabletopBadges_01.png", 32],
            ["TabletopBadges_12.PNG", 8],
            ["TabletopBadges_23.PNG", 1],
            ["TabletopBadges_25.PNG", 1],
            ["TabletopBadges_26.PNG", 1],
        ];

        const rand_arr_n2 = [
            ["TabletopBadges_14.PNG", 32],
            ["TabletopBadges_15.PNG", 4],
            ["TabletopBadges_19.PNG", 1],
            ["TabletopBadges_20.PNG", 1],
        ];

        const rand_arr_n3 = [
            ["TabletopBadges_16.PNG", 3],
            ["TabletopBadges_17.PNG", 1],
        ];

        const rand_arr_1 = rand_arr_n1.map(x => [atlasIndexFromFilename[x[0]], x[1]]);
        const rand_arr_2 = rand_arr_n2.map(x => [atlasIndexFromFilename[x[0]], x[1]]);
        const rand_arr_3 = rand_arr_n3.map(x => [atlasIndexFromFilename[x[0]], x[1]]);

        let rand_arr;
        if (x < 140) {
            rand_arr = rand_arr_1;
        } else if (x < 160) {
            if (Math.random() * 20 < x - 140) {
                rand_arr = rand_arr_2;
            } else {
                rand_arr = rand_arr_1;
            }
        } else if (x < 240) {
            rand_arr = rand_arr_2;
        } else if (x < 260) {
            if (Math.random() * 20 < x - 240) {
                rand_arr = rand_arr_3;
            } else {
                rand_arr = rand_arr_2;
            }
        } else {
            rand_arr = rand_arr_3;
        }
        const total_weight = rand_arr.reduce((acc, cur) => acc + cur[1], 0);
        const rand_arr_weight = [];
        for (let r of rand_arr) {
            for (let i = 0; i < r[1]; i++) {
                rand_arr_weight.push(r[0]);
            }
        }
        const idx_weight = Math.floor(((Math.random() * total_weight) % total_weight));
        const idx = rand_arr_weight[idx_weight];
        return offsetAndSizeVectorArray[idx];
    })), [offsetAndSizeVectorArray, atlasIndexFromFilename])

    const canDiscardArray = useMemo(() => Float32Array.from(new Array(size).fill().flatMap((_, i) => {
        if (i >= 100000) {
            return [1];
        }
        return [0];
    })), []);

    const ref = useRef()
    useLayoutEffect(() => {

        const x_unit = new THREE.Vector3(1, 0, 0);
        const y_unit = new THREE.Vector3(0, 1, 0);
        const quaternion_x90 = (new THREE.Quaternion()).setFromAxisAngle(x_unit, MathUtils.degToRad(90));
        const quaternion_x270 = (new THREE.Quaternion()).setFromAxisAngle(x_unit, MathUtils.degToRad(270));
        const quaternion_y90 = (new THREE.Quaternion()).setFromAxisAngle(y_unit, MathUtils.degToRad(90));
        const quaternion_face1 = (new THREE.Quaternion()).premultiply(quaternion_x270).premultiply(quaternion_x90);
        const quaternion_face2 = (new THREE.Quaternion()).premultiply(quaternion_x270).premultiply(quaternion_x90).premultiply(quaternion_y90);
        const quaternion_face3 = (new THREE.Quaternion()).premultiply(quaternion_x270).premultiply(quaternion_x90).premultiply(quaternion_y90).premultiply(quaternion_y90);
        const quaternion_face4 = (new THREE.Quaternion()).premultiply(quaternion_x270).premultiply(quaternion_x90).premultiply(quaternion_y90).premultiply(quaternion_y90).premultiply(quaternion_y90);

        let i = 0;
        let elevation_levels = [];
        const yPerELevel = 0.15;
        // terrain ground
        for (let x = 0; x < 400; x++) {
            for (let z = 0; z < 250; z++) {
                const id = i++;
                let last_elevation = null;
                let lec = 0;
                if (z > 0) {
                    const prev_elev = elevation_levels[x * 250 + z - 1];
                    last_elevation = last_elevation !== null ? last_elevation + prev_elev : prev_elev;
                    lec++;
                }
                if (x > 0) {
                    const prev_elev = elevation_levels[(x - 1) * 250 + z];
                    last_elevation = last_elevation !== null ? last_elevation + prev_elev : prev_elev;
                    lec++;
                }
                if (last_elevation === null) last_elevation = 100;
                else last_elevation /= lec;

                let elevation_diff = (Math.floor((Math.random() * 31) % 31) - 10);
                if (elevation_diff > 10) elevation_diff = 0;
                let elevation_level = last_elevation + elevation_diff;
                if (elevation_level < 0) elevation_level = 0;
                else if (elevation_level > 200) elevation_level = 200;

                elevation_levels.push(elevation_level);
                tempObject.position.set(5 + x * 10, elevation_level * yPerELevel, 5 + z * 10);
                tempObject.quaternion.copy(quaternion_x270);
                // tempObject.rotateY(THREE.MathUtils.degToRad(90 * Math.floor(((Math.random() * 4) % 4))));
                tempObject.updateMatrix();
                ref.current.setMatrixAt(id, tempObject.matrix);
            }
        }
        // terrain side
        for (let x = 0; x < 400; x++) {
            for (let z = 0; z < 250; z++) {
                for (let w = 0; w < 4; w++) {
                    const id = i++;
                    const elevation_level = elevation_levels[x * 250 + z];
                    tempObject.position.set(0, 0, 0);
                    tempObject.quaternion.set(0, 0, 0, 1);
                    if (w === 0) {
                        tempObject.quaternion.copy(quaternion_face1);
                        tempObject.position.set(5 + x * 10, (elevation_level * yPerELevel) - 20, 5 + z * 10 + 4.999);
                    } else if (w === 1) {
                        tempObject.quaternion.copy(quaternion_face2);
                        tempObject.position.set(5 + x * 10 + 4.999, (elevation_level * yPerELevel) - 20, 5 + z * 10);
                    } else if (w === 2) {
                        tempObject.quaternion.copy(quaternion_face3);
                        tempObject.position.set(5 + x * 10, (elevation_level * yPerELevel) - 20, 5 + z * 10 - 4.999);
                    } else {
                        tempObject.quaternion.copy(quaternion_face4);
                        tempObject.position.set(5 + x * 10 - 4.999, (elevation_level * yPerELevel) - 20, 5 + z * 10);
                    }
                    tempObject.scale.y = 4;
                    // tempObject.rotateX(THREE.MathUtils.degToRad(90));
                    // tempObject.rotateZ(THREE.MathUtils.degToRad(90 * w));
                    tempObject.updateMatrix();
                    ref.current.setMatrixAt(id, tempObject.matrix);
                }
            }
        }
        ref.current.instanceMatrix.needsUpdate = true
    }, [])

    const tileClickTracker = useRef({
        instanceId: -1,
    });

    useEffect(() => {
        cameraMovingEventRef.current = (e) => {
            if (tileClickTracker.current.instanceId >= 0) {
                tileClickTracker.current.instanceId = -1;
            }
        };
        return () => {
            cameraMovingEventRef.current = null;
        }
    }, [cameraMovingEventRef])

    const tileMouseDown = useCallback((e) => {
        if (e.button === 0 && e.instanceId < 100000) {
            e.stopPropagation();
            tileClickTracker.current.instanceId = e.instanceId;
        }
    }, [focusCameraOn, tileClickTracker]);

    const tileMouseUp = useCallback((e) => {
        e.stopPropagation();
        const instanceId = tileClickTracker.current.instanceId;
        if (instanceId >= 0) {
            const planePoint = new THREE.Vector3();
            const plane = new THREE.Plane();
            plane.setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 1, 0), new THREE.Vector3());
            const direction = cameraControllerRef.current.target.clone().sub(cameraControllerRef.current.object.position);
            const ray = new THREE.Ray(e.point, direction);
            ray.intersectPlane(plane, planePoint);
            focusCameraOn(planePoint.x, planePoint.z);
            tileClickTracker.current.instanceId = -1;
        }
    }, [focusCameraOn, tileClickTracker]);

    const materialRef = useRef();
    window.materialRef = materialRef;
    useFrame(() => {
        const cam_height = cameraControllerRef.current.object.position.y;
        if(cam_height > 1500) {
            materialRef.current.uniforms.shouldDiscard.value = 1.0;
        } else if(cam_height > 1000) {
            materialRef.current.uniforms.shouldDiscard.value = MathUtils.lerp(0.0, 1.0, (cam_height - 1000) / 500.0);
        } else {
            materialRef.current.uniforms.shouldDiscard.value = 0.0;
        }
        if (cam_height >= 4200) {
            materialRef.current.uniforms.u_fogFar.value = 12000;
            materialRef.current.uniforms.u_fogNear.value = 4000;
        } else if (cam_height >= 2200) {
            materialRef.current.uniforms.u_fogFar.value = MathUtils.lerp(12000, 120000, (cam_height - 2200) / 2000.0);
            materialRef.current.uniforms.u_fogNear.value = MathUtils.lerp(4000, 40000, (cam_height - 2200) / 2000.0);
        } else if (cam_height >= 200) {
            materialRef.current.uniforms.u_fogFar.value = MathUtils.lerp(1200, 12000, (cam_height - 200) / 2000.0);
            materialRef.current.uniforms.u_fogNear.value = MathUtils.lerp(400, 4000, (cam_height - 200) / 2000.0);
        } else {
            materialRef.current.uniforms.u_fogFar.value = 1200;
            materialRef.current.uniforms.u_fogNear.value = 400;
        }
    }, [materialRef]);

    return (
        <instancedMesh ref={ref} args={[null, null, size]} onPointerDown={tileMouseDown} onPointerUp={tileMouseUp} visible={true}>
            <planeBufferGeometry attach="geometry" args={[10, 10]}>
                <instancedBufferAttribute attachObject={['attributes', 'offsetAndTileSize']} args={[offsetAndSizeFlatArray, 4]}/>
                <instancedBufferAttribute attachObject={['attributes', 'canDiscard']} args={[canDiscardArray, 1]}/>
            </planeBufferGeometry>
            <atlasMaterial ref={materialRef} attach="material" map={colorMap} size={AtlasTerrain.meta.size.w} u_fogColor={[0.051, 0.09, 0.13, 1.0]} u_fogNear={200} u_fogFar={400} shouldDiscard={0.0}/>
        </instancedMesh>
    )
}

const minTarget = new THREE.Vector3(0, 0, 0);
const maxTarget = new THREE.Vector3(4000, 0, 2500);
const centerTarget = new THREE.Vector3().add(minTarget).add(maxTarget).divideScalar(2.0);

function World() {

    const cameraControllerRef = useRef();
    window.cameraControllerRef = cameraControllerRef;

    useFrame(() => {
        const cam_height = cameraControllerRef.current.object.position.y;
        if (cam_height >= 2500) {
            cameraControllerRef.current.maxPolarAngle = 0;
            cameraControllerRef.current.minPolarAngle = 0;
        } else if (cam_height >= 500) {
            cameraControllerRef.current.maxPolarAngle = MathUtils.lerp(THREE.MathUtils.degToRad(60), 0, (cam_height - 500) / 2000.0);
            cameraControllerRef.current.minPolarAngle = 0;
        } else if (cam_height >= 200) {
            cameraControllerRef.current.maxPolarAngle = THREE.MathUtils.degToRad(60);
            cameraControllerRef.current.minPolarAngle = 0;
        } else {
            cameraControllerRef.current.maxPolarAngle = MathUtils.lerp(THREE.MathUtils.degToRad(70), THREE.MathUtils.degToRad(60), (cam_height) / 300.0);
            cameraControllerRef.current.minPolarAngle = MathUtils.lerp(THREE.MathUtils.degToRad(10), 0, (cam_height) / 300.0);
        }

        if (cam_height >= 1500) {
            const progress = CubicBezier(Math.max(0.0, Math.min(1.0, (cam_height - 1500.0) / 2500.0)), 0.0, 0.8, 0.9, 1.0) * 0.9;
            cameraControllerRef.current.minTarget.lerpVectors(minTarget, centerTarget, progress);
            cameraControllerRef.current.maxTarget.lerpVectors(maxTarget, centerTarget, progress);
        } else {
            cameraControllerRef.current.minTarget.copy(minTarget);
            cameraControllerRef.current.maxTarget.copy(maxTarget);
        }


    }, [cameraControllerRef]);

    const cameraSpringRef = useRef({
        active: false,
    });
    useFrame(() => {
        if (cameraSpringRef.current.active) {
            const animationTime = cameraSpringRef.current.animationTime;
            const elapsed = cameraSpringRef.current.clock.getElapsedTime();
            const animationProgress = CubicBezier(Math.max(0.0, Math.min(1.0, elapsed / animationTime)), 0.0, 0.8, 0.9, 1.0);
            cameraControllerRef.current.target.lerpVectors(cameraSpringRef.current.fromTarget, cameraSpringRef.current.toTarget, animationProgress);
            cameraControllerRef.current.object.position.lerpVectors(cameraSpringRef.current.fromPosition, cameraSpringRef.current.toPosition, animationProgress);
            if (elapsed >= animationTime) {
                cameraSpringRef.current.clock.stop();
                cameraSpringRef.current = {
                    active: false,
                }
            }
        }
    });
    const focusCameraOn = useCallback((x, z, animationTime = 0.4) => {

        const fromTarget = cameraControllerRef.current.target.clone();
        const newTarget = new THREE.Vector3(x, 0, z);
        const fromPos = cameraControllerRef.current.object.position.clone();
        const newPos = fromPos.clone().add(newTarget).sub(fromTarget);

        if(newPos.clone().sub(newTarget).length() > 300) {
            const cameraDirection = newTarget.clone().sub(newPos).normalize();
            newPos.copy(newTarget).sub(cameraDirection.multiplyScalar(100));
        }

        cameraSpringRef.current = {
            active: true,
            clock: new THREE.Clock(),
            animationTime: animationTime,
            fromTarget: fromTarget,
            toTarget: newTarget,
            fromPosition: fromPos,
            toPosition: newPos,
        }
    }, [cameraSpringRef]);
    window.focusCameraOn = focusCameraOn;

    const cameraMovingEventRef = useRef(null);

    return (
        <>
            <Stats showPanel={0} className={"stat-1"}/>
            <Stats showPanel={2} className={"stat-2"}/>
            <Terrain focusCameraOn={focusCameraOn} cameraMovingEventRef={cameraMovingEventRef} cameraControllerRef={cameraControllerRef}/>
            <MapControls ref={cameraControllerRef} target={[0, 0, 0]} minDistance={50} maxDistance={4000} maxPolarAngle={THREE.MathUtils.degToRad(60)} enableDamping={true} onChange={(e) => (cameraMovingEventRef.current ? cameraMovingEventRef.current(e) : null)}/>
            <PerspectiveCamera makeDefault far={1000000} position={[100, 200, 100]}/>
        </>
    )
}

function App() {
    return (
        <Suspense fallback={<div>Loading... </div>}>
            <Canvas mode={"concurrent"}>
                <World/>
            </Canvas>
        </Suspense>
    );
}

export default App;
